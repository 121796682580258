
import {
  CategoryTypes,
  TicketCategory,
} from "@/store/modules/category/category.types";
import { Pagination } from "@/types/types";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const categoryX = namespace("Category");

@Component({})
export default class CategorySelect extends Vue {
  @Prop() public label?: string;
  @Prop() public filled?: boolean;
  @Prop() public outlined?: boolean;
  @Prop() public dense?: boolean;
  @Prop() public hideDetails?: boolean;
  @Prop() public rules?: unknown[];
  @Prop() value?: number;

  @categoryX.Action(CategoryTypes.LOAD_CATEGORYS)
  public getCategorys!: (pagination: Pagination) => void;

  @categoryX.State(CategoryTypes.CATEGORIES)
  public categories!: TicketCategory[];

  @categoryX.State(CategoryTypes.LOADING_CATEGORIES_STATE)
  public loadingCategorys!: boolean;

  @Emit("input")
  save(id: string): string {
    return id;
  }

  mounted(): void {
    this.getCategorys({ page: 1, limit: 10 });
  }
}
